<template>
  <div class="loginbox">
    <div class="loginBody-image">
      <img class="image" :src="appLogo" />
      <!-- <span class="spanTv">{{ appName }}</span> -->
    </div>
    <div class="loginBody">
      <div>
        <van-field
          v-model="loginform.username"
          clearable
          :placeholder="$t('register.enterusername')"
          style="padding-top: 14px; border-bottom: 0.5px solid #E8F2FE; background-color: #00000000;"
        >
        </van-field>
        <van-field
          v-model="loginform.password"
          :type="type"
          right-icon="closed-eye"
          :placeholder="$t('login.password')"
          @click-right-icon="openeye"
          style="margin-top: 7.2vw; padding-top: 14px;  border-bottom: 0.5px solid #E8F2FE; background-color: #00000000;"
        >
        </van-field>
      </div>
      <div
        class="loginTvTo"
        @click="login"
        v-if="loginform.username && loginform.password && loginform.password.length >= 6"
      >
        {{ $t("login.login") }}
      </div>
      <div class="loginTv" v-else>
        {{ $t("login.login") }}
      </div>
      <van-row type="flex" class="foundPwd">
        <van-col span="0">
          <span @click="register">{{ $t("login.register") }}</span>
        </van-col>
      </van-row>
    </div>
	<div class="line"><div class="text">{{ $t("register.initialize") }}</div></div>
    <div class="bottomV">
      <div class="yuyanV" @click="showLang = true">
        <img class="yuyanIv" src="../../assets/img/yuyan.svg"/>
        <span class="yuyanTv">{{ $t("set.lang") }}</span>
      </div>
      <span style="width: 3rem;" />
      <div class="yuyanV" @click="toserver">
        <img class="yuyanIv" src="../../assets/img/kefu.svg"/>
        <span class="yuyanTv">{{ $t("login.serverurl") }}</span>
      </div>
    </div>

    <van-popup v-model="showLang" position="bottom">
      <div style="padding-bottom: 1rem;">
        <div class="pupTitle">{{ $t("语言选择") }}</div>
        <div
          class="pupItem"
          v-for="(item, index) in option"
          :key="index"
          @click="onLanguage(item)"
        >
          <span class="pupItemTv">{{ item.text }}</span>
          <van-icon v-if="lang == item.value" size="25" name="success" />
        </div>
      </div>
    </van-popup>

    <van-popup 
        :close-on-click-overlay="false"
        v-model="showDialog" style="background-color:transparent; overflow:hidden;"
    >
        <div class="loadingWrap">
            <van-loading v-if="showDialog" type="spinner" />
        </div>
    </van-popup>
  </div>
</template>
<script>
let Base64 = require('js-base64').Base64;
export default {
  data() {
    return {
      lang: localStorage.getItem("lang") || "en",
      option: [
        { text: "English", value: "en" },
        { text: "繁体中文", value: "ru" }, //繁体中文
        { text: "اللغة العربية", value: "zh" },//阿拉伯语
        { text: "日本語", value: "fr" },
        { text: "Tiếng Việt", value: "it" }, //越南语
        { text: "Español", value: "es" }, //西班牙语
        { text: "Deutsch", value: "de" }, //德语
        { text: "Français", value:"frpro"},//法语
        { text: "Italiano", value:"itpro"},//意大利语
      ],
      loginform: {
        username: "",
        password: "",
      },
      type: "password",
      serverurl: "",
      appLogo: "",
      appName: "",
      showLang: false,
      showDialog: false,
    };
  },
  created() {
    if (!localStorage.getItem("lang")) {
      // localStorage.setItem("lang", getPar("lang"));
      localStorage.setItem("lang", this.lang);
      this.$i18n.locale = this.lang;
    } else {
    }
    this.getserver();
    this.getLogo();
    
    // this.getlang("token不能为空", this.lang);
  },
  methods: {
    openeye(event) {
      if (this.type === "password") {
        event.target.className = "van-icon van-icon-eye";
        this.type = "text";
      } else {
        event.target.className = "van-icon van-icon-closed-eye";
        this.type = "password";
      }
    },
    async getLogo() {
      const { data } = await this.$http.get("/home/index/getConf");
      if (data) {
        if (data.code === 200) {
          this.appLogo = data.data.imageurl;
          this.appName = data.data.sitename;
        }
      }
    },
    // 获取客服链接
    async getserver() {
      const { data } = await this.$http.get("/home/index/serviceurl");
      if (data) {
        if (data.code === 200) {
          let serUuu = Base64.encode('{"uid":"暂无登录"}');
          this.serverurl = data.data+"&append_info="+serUuu;
        }
      }
    },
    // 登录
    async login() {
      if (this.loginform.username === "" || this.loginform.password === "") {
        this.$toast.fail(this.$t("login.complete"));
        return false;
      }
      this.showDialog = true;
      const { data } = await this.$http.post(
        "/home/index/login",
        this.loginform
      );
      if (data) {
        if (data.code === 200) {
          
          // this.$toast.success(this.getlang(data.msg, this.lang))
          window.localStorage.setItem("token", data.data.token);
          window.localStorage.setItem("user_name_id", data.data.username);
          this.$router.push("/");
        } else {
          this.$toast.fail(this.getlang(data.msg, this.lang));
        }
      }
      this.showDialog = false;
    },
    // 用户协议
    agreement() {
      this.$router.push({ path: "/agreement" });
    },
    // 忘记密码
    forget() {
      this.$router.push({ path: "/forget" });
    },
    // 快速注册
    register() {
      this.$router.push({ path: "/register" });
    },
    onLanguage(item) {
      let lang = item.value;
      this.$i18n.locale = lang;
      this.lang = lang;
      localStorage.setItem("lang", lang);
      this.showLang = false;
    },
    // 客服页面
    toserver() {
      window.location.href = this.serverurl;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-field__control {
  color: #333;
  &::placeholder {
    color: #999;
  }
}

.van-cell:after {
  border-bottom: 0;
}

// /deep/ .van-cell {
//   background-color: #ffff;
// }
.loginbox {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  color:#333;
  //background: #000; //登录也背景图
  background-size: cover;
  position: relative;
  padding: 2.75rem 2rem 0 2rem;
  .lang {
    position: absolute;
    right: 1.25rem;
    top: 0.8rem;
    color: #333;
    font-size: 0.875rem;
    .van-dropdown-menu /deep/.van-dropdown-menu__bar {
      box-shadow: none;
      height: 1rem;
      background: transparent !important;
    }
    .van-dropdown-menu /deep/ .van-dropdown-menu__title {
      color: #777777 !important; //语言字体
    }
    .van-dropdown-menu /deep/ .van-popup {
      width: 30%;
      left: 68%;
      border-radius: 0.4rem;
      box-shadow: 0 0.13333rem 0.4rem #004ea3;
    }
    .van-dropdown-item__option,
    .van-dropdown-item__option--active {
      margin: 0;
      text-align: center;
    }
    .van-cell__title,
    .van-dropdown-item__option--active .van-dropdown-item__icon {
      color: #004ea3 !important;
    }
  }
  .kefu {
    position: absolute;
    right: 1rem;
    top: 2.4rem;
    font-size: 0.875rem;
    color: #0a51a1; //客服字体
  }
  .loginBody-image {
    margin-top: 25%;
    height: 20%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	.image{ height: 80px;}
    .spanTv {
      font-weight: 700;
      font-size: 1.5rem;
      color: #333;
      margin-top: 0.5rem;
      margin-left: 1rem;
    }
  }
  .loginBody {
    margin-top: 15%;
    margin-left: 5%;
    width: 90%;
    padding-bottom: 3%;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .van-field {
    padding: 30px 0 15px 0;
    font-size: 1rem;

    // /deep/.van-cell-group van-hairline--top-bottom {
    //   color: #004ea3;
    // }
    /deep/.van-field__right-icon,
    /deep/.van-field__left-icon {
      color: #333;
      font-size: 1.2rem;
    }
    .van-icon-clear {
      color: #333;
      font-size: 1rem;
    }
    .van-field__control {
      padding-left: 5px;
    }
  }

  .foundPwd {
    font-size: 0.875rem;
    margin-top: 2rem;
    .van-col:first-child {
      text-align: left;
      color: #333;
    }
    .van-col:last-child {
      text-align: right;
      color: #333;
    }
  }
  .loginTvTo {
    margin-top: 2rem;
    width: 100%;
    height: 2.66667rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #f0b90b;
    border-radius: 0.4rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginTv {
    margin-top: 2rem;
    width: 100%;
    height: 42px;
	line-height: 42px;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #f2d169;
    border-radius: 0.4rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agreement {
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    /deep/.van-icon {
      height: 1rem;
      width: 1rem;
      font-size: 0.66667rem;
      color: #333!important;
      border-radius: 4px;
    }
    /deep/.van-checkbox__label {
      margin-left: 3px;
      margin-right: 3px;
    }
    .van-checkbox__icon--checked .van-icon {
      color: #333 !important;
      background-color: #004ea3;
      border-color: #004ea3;
      border-radius: 4px;
      font-size: 0.66667rem;
    }
  }
  .iconfont {
    color: #004ea3;
    font-size: 18px;
  }

  .bottomV {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 5rem;
  }
  .yuyanV {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  .yuyanIv {
    width: 1rem;
    height: 1rem;
  }

  .yuyanTv {
    font-size: 0.9rem;
    color: #333;
    margin-top: 1rem;
    max-width: 120px;
  }

  .pupTitle {
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }

  .pupItem {
    height: 11vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;

    .pupItemTv {
      color: #333;
    }

    .pupItemIv {
      width: 1rem;
      height: 1rem;
      background-color: #ff0000;
    }
  }
}
.line{ border-bottom:1px solid #aaa; height: 0px; margin-top:30px; 
	.text{ margin: 0 auto; color:#aaa; font-size: 12px; width: 60px; height:20px; line-height: 20px; background-color: #fff; position: relative; top:-7px;}
}
</style>
